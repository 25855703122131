.inputContainer {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 20px;
    height: 20px;
  }
}
