.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    white-space: pre-wrap;
    margin: 3px 20px;
    padding: 0 2px;
  }
  input {
    width: 100%;
  }
}
