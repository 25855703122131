.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 105px;
  background-color: var(--main-color);
  div {
    max-width: 1000px;
    width: 100%;
    margin: 0 30px;
    padding-left: 10px;
  }
}

.title {
  color: #fff;
  width: 100%;
  margin: auto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
