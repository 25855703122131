.optionContainer {
  margin: 20px 0;
  ._label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 9px;
  }
  .selector {
    width: 205px;
  }
}
