.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  ._label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 9px;
  }
  .inputText {
    font-size: 16px;
    width: 99%;
    height: 52px;
    border-radius: 6px;
    box-sizing: border-box;
    border: 1px solid #16abf8;
    padding: 14px 18px;
  }
  .inputText:focus {
    outline: 2px solid #16abf8;
  }
}
