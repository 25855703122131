.container {
  width: 100%;
  height: 100%;
  max-width: 830px;
  max-height: 403px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .section {
    padding: 10px 30px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

section.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #bbb;
  div {
    font-weight: bold;
    font-size: 18px;
  }
}
section.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
section.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
}
