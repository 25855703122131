.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  .header {
    margin: 40px 10px;
    display: flex;
    justify-content: space-between;
    span.activity {
      font-size: 36px;
      font-weight: 700;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
  }
}
