@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
:root{--main-color: #16abf8}body{margin:0;font-family:"Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
.styles_root__2cYXc{min-height:100vh;width:100vw;background-color:#eee;display:flex;flex-direction:column}.styles_content__22tdb{flex-grow:1;display:flex;flex-direction:column;align-items:center;margin:20px}.styles_content__22tdb .styles_wrapper__3Ri6l{display:flex;max-width:1000px;flex-grow:1;width:100%}
.styles_header__1iJYS{display:flex;justify-content:center;align-items:center;width:100vw;height:105px;background-color:var(--main-color)}.styles_header__1iJYS div{max-width:1000px;width:100%;margin:0 30px;padding-left:10px}.styles_title__3hsqQ{color:#fff;width:100%;margin:auto;font-size:24px;font-style:normal;font-weight:700;text-transform:uppercase}
.styles_btn__DBiu5{cursor:pointer;display:flex;justify-content:space-between;align-items:center;border-radius:45px;border:0;padding:13.5px 29px;font-size:18px;font-weight:600;font-family:inherit;background-color:var(--main-color);border:2px solid #e5e5e5;color:#fff;min-width:170px}.styles_btn__DBiu5 .styles_label__3aNgm{font-size:18px}.styles_btn__DBiu5:hover{border:2px solid var(--main-color)}.styles_disable__3rStt{cursor:not-allowed;background-color:#16abf8;opacity:.2}@media only screen and (max-width: 600px){.styles_label__3aNgm{display:none}.styles_btn__DBiu5{min-width:0}}
.styles_btn__MhmEZ{cursor:pointer;display:flex;justify-content:center;align-items:center;border-radius:45px;border:0;padding:13.5px 29px;font-size:18px;font-weight:600;font-family:inherit;background-color:var(--main-color);border:2px solid #e5e5e5;color:#fff;min-width:170px}.styles_btn__MhmEZ .styles__label__-1htk{font-size:18px}.styles_btn__MhmEZ:hover{border:2px solid var(--main-color)}.styles_disable__1oU73{cursor:not-allowed;opacity:.2}
.styles_icon__2TkJg{cursor:pointer}
.styles_sortButtonContainer__1VKes{display:flex}
.styles_container__2fwe2{display:flex;flex-direction:column;width:100%}.styles_container__2fwe2 .styles__label__3ywax{font-size:12px;font-weight:600;margin-bottom:9px}.styles_container__2fwe2 .styles_inputText__ql-6c{font-size:16px;width:99%;height:52px;border-radius:6px;box-sizing:border-box;border:1px solid #16abf8;padding:14px 18px}.styles_container__2fwe2 .styles_inputText__ql-6c:focus{outline:2px solid #16abf8}
.styles_inputContainer__lsTmt{margin-right:15px;display:flex;align-items:center;justify-content:center}.styles_inputContainer__lsTmt input{width:20px;height:20px}
.styles_person__25gAQ{width:100%;display:flex;justify-content:center;cursor:pointer}
.styles_optionContainer__3A3H-{margin:20px 0}.styles_optionContainer__3A3H- .styles__label__FWH9x{font-size:12px;font-weight:600;margin-bottom:9px}.styles_optionContainer__3A3H- .styles_selector__3b7HK{width:205px}
.styles_dropDownMenuContainer__3-Z2m{background:#fff;border:1px solid #e5e5e5;box-shadow:0px 6px 15px 5px rgba(0,0,0,.1);border-radius:6px}.styles_menuContainer__3Kcot{border-bottom:1px solid #e5e5e5;display:flex;height:52px;align-items:center;padding:0 20px;justify-content:space-between}.styles_menuContainer__3Kcot:hover{background-color:#e5e5e5}.styles_menuLeft__1QTrK{display:flex;align-items:center}.styles_menuLeft__1QTrK span{margin-left:20px}.styles_icon__3nN0H{display:flex}.styles_menuRight__9gTQV{display:flex}
.styles_container__1gN5T{display:flex;flex-direction:column;width:100%;flex-grow:1}.styles_container__1gN5T .styles_header__1YmMM{margin:40px 10px;display:flex;justify-content:space-between}.styles_container__1gN5T .styles_header__1YmMM span.styles_activity__2Tel_{font-size:36px;font-weight:700}.styles_container__1gN5T .styles_content__2CiWQ{display:flex;flex-wrap:wrap}
.styles_container__SOHdu{display:flex;flex-direction:column;width:100%;flex-grow:1}.styles_container__SOHdu .styles_header__D_QSW{margin:40px 10px;display:flex;justify-content:space-between;align-items:center}.styles_container__SOHdu .styles_content__33wzo{display:flex;flex-wrap:wrap}.styles_text__3-j65{background-color:#eee;font-size:36px;font-weight:700}input.styles_text__3-j65{font-size:36px;font-family:Poppins;padding-left:20px}input.styles_text__3-j65:focus{outline:none !important;outline-style:none !important;box-shadow:none !important;border-color:transparent !important}.styles_headerLeft__1h0Zr{display:flex;align-items:center}.styles_headerRight__M5hFB{display:flex;align-items:center}.styles_sortButton__2FxQR{margin-right:12px;cursor:pointer}.styles_dropMenu__35dvr{position:absolute;margin-top:8px;background-color:#fff;border-radius:6px;width:235px}
.styles_container__3dDes{display:flex;flex-direction:row;align-items:center}.styles_container__3dDes span{white-space:pre-wrap;margin:3px 20px;padding:0 2px}.styles_container__3dDes input{width:100%}
.styles_container__1WzeI{display:flex;position:absolute;width:100vw;height:100vh;background-color:rgba(0,0,0,.5);top:0;left:0;justify-content:center;align-items:center}
.styles_activityCard__z9IDu{box-shadow:0 4px 8px rgba(0,0,0,.15);width:177px;height:177px;margin:13px 10px;border-radius:12px;padding:22px 26px;background-color:#fff;display:flex;flex-direction:column;justify-content:space-between}.styles_link__2aRPh{text-decoration:none;cursor:pointer;display:flex;flex-grow:1}.styles_activityBody__1TMSd{width:100%}.styles_activityBody__1TMSd h4{margin:0;color:#222;font-weight:700;font-size:24px}.styles_cardFooter__17Ggx{display:flex;justify-content:space-between;align-items:center}.styles_activityTime__OoO3V{display:flex;color:#888}.styles_activityRemove__2Udiy{display:flex}
body.react-confirm-alert-body-element{overflow:hidden}.react-confirm-alert-blur{-webkit-filter:url(#gaussian-blur);filter:url(#gaussian-blur);filter:blur(2px);-webkit-filter:blur(2px)}.react-confirm-alert-overlay{position:fixed;top:0;left:0;right:0;bottom:0;z-index:99;background:rgba(17,17,17,.9);display:-moz-flex;display:-ms-flex;display:-o-flex;display:flex;justify-content:center;-ms-align-items:center;align-items:center;opacity:0;animation:react-confirm-alert-fadeIn .2s .2s forwards}.react-confirm-alert-body{font-family:Arial,Helvetica,sans-serif;width:400px;padding:30px;text-align:left;background:#fff;border-radius:10px;box-shadow:0 20px 75px rgba(0,0,0,.13);color:#666}.react-confirm-alert-svg{position:absolute;top:0;left:0}.react-confirm-alert-body>h1{margin-top:0}.react-confirm-alert-body>h3{margin:0;font-size:16px}.react-confirm-alert-button-group{display:-moz-flex;display:-ms-flex;display:-o-flex;display:flex;justify-content:flex-start;margin-top:20px}.react-confirm-alert-button-group>button{outline:none;background:#333;border:none;display:inline-block;padding:6px 18px;color:#eee;margin-right:10px;border-radius:5px;font-size:12px;cursor:pointer}@keyframes react-confirm-alert-fadeIn{from{opacity:0}to{opacity:1}}
.styles_loader__34GbQ{height:100%;width:100%;display:flex;align-items:center;justify-content:center}
.styles_container__1cVmo{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.styles_container__1cVmo span{color:#aaa}
.styles_container__1EJp3{max-height:355px;max-width:490px;left:0px;top:0px;border-radius:12px;background-color:#fff;display:flex;flex-direction:column;justify-content:space-between;align-items:center;border:1px solid #000;padding:50px 63px 43px 63px}.styles_btn__o6aOL{display:flex;align-items:center}.styles_btn__o6aOL button{margin:0 2px}.styles_text__3AIhP{margin:10px 0 20px 0;display:flex;flex-direction:column;align-items:center}.styles_text__3AIhP .styles_targetTitle__340Br{font-weight:700}
.styles_container__3Oty9{display:flex;width:100%;max-width:490px;border-radius:10px;align-items:center;padding:5px 20px;background-color:#eee;height:50px}.styles_container__3Oty9 span{margin-left:10px}.styles_container__3Oty9 div{display:flex}
.styles_container__18rzC{display:flex;width:100%;max-width:490px;border-radius:10px;align-items:center;padding:5px 20px;background-color:#eee;width:500px}.styles_container__18rzC span{margin-left:10px}.styles_container__18rzC div{display:flex}
.styles_container__13BbX{width:100%;height:100%;max-width:830px;max-height:403px;background-color:#fff;border-radius:12px;box-shadow:0px 4px 10px rgba(0,0,0,.1)}.styles_container__13BbX .styles_section__3IcVV{padding:10px 30px;height:90%;display:flex;flex-direction:column;justify-content:space-between}section.styles_head__16n66{display:flex;justify-content:space-between;align-items:center;height:60px;border-bottom:1px solid #bbb}section.styles_head__16n66 div{font-weight:bold;font-size:18px}section.styles_footer__fowyg{display:flex;justify-content:flex-end;align-items:center}section.styles_form__2VwlH{display:flex;flex-direction:column;flex-grow:1;justify-content:flex-start;align-items:flex-start;padding:20px 0}
.styles_todoContainer__3-qVZ{display:flex;flex-direction:column;padding:0 10px;width:100%}.styles_todoContainer__3-qVZ .styles_todo__3TbZx{margin-bottom:20px;height:80px;background:#fff;box-shadow:0px 6px 10px rgba(0,0,0,.1);border-radius:12px;display:flex;align-items:center;justify-content:space-between;padding:0 28px}.styles_leftTodo__PZgMY{display:flex;align-items:center}.styles_leftTodo__PZgMY span{font-size:18px;font-weight:500;line-height:27px;font-family:Poppins;font-style:normal}.styles_notActive__2xn8f{font-family:Poppins;font-style:normal;font-weight:500;font-size:18px;line-height:27px;-webkit-text-decoration-line:line-through;text-decoration-line:line-through;color:#888}
