.dropDownMenuContainer {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 6px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.menuContainer {
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  height: 52px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

.menuContainer:hover {
  background-color: #e5e5e5;
}
.menuLeft {
  display: flex;
  align-items: center;
  span {
    margin-left: 20px;
  }
}
.icon {
  display: flex;
}
.menuRight {
  display: flex;
}
